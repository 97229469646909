<template>
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <Breadcrumb :datas='breadcrumb_data'/>
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid">
                <!-- begin::Card-->
                <div class="card card-custom overflow-hidden">
                    <div class="card-header">
                      <div class="card-title">
                        <h3 class="card-label">{{ $t('commons.details') }}</h3>
                      </div>
                    </div>
                    <div class="card-body p-0">
                        <!-- begin: Invoice-->
                        <!-- begin: Invoice header-->
                        <div class="row justify-content-center py-8 px-8 py-md-15 px-md-0">
                            <div class="col-md-10">
                                <div class="d-flex justify-content-between pt-2">
                                    <div class="row w-100">
                                        <div class="col-md-6 col-sm-12">
                                        <div class="row">
                                            <p class="col-md-4 col-sm-12">{{ $t('commons.name') }} {{ $t('commons.surname') }}:</p>
                                            <b class="col-md-8 col-sm-12">{{ userFullName }}</b>
                                        </div>
                                        <div class="row mt-2">
                                            <p class="col-md-4 col-sm-12">{{ $t('c_management.identity_no') }}:</p>
                                            <b class="col-md-8 col-sm-12">{{ user.identity_number || '-' }}</b>
                                        </div>
                                        <div class="row mt-2">
                                            <p class="col-md-4 col-sm-12">{{$t('commons.cost') }}:</p>
                                            <b class="col-md-8 col-sm-12">{{ withdraw.amount_formatted || '-' }}</b>
                                        </div>
                                        <div class="row mt-2">
                                            <p class="col-md-4 col-sm-12">{{ $t('commons.status') }}:</p>
                                            <b class="col-md-8 col-sm-12">
                                                <span class="badge" :class="statusClass">
                                                    {{ statusLabel }}
                                                </span>
                                            </b>
                                        </div>
                                        <div class="row mt-2" v-if="+withdraw.status === 40">
                                            <p class="col-md-4 col-sm-12">{{$t('f_management.reason_for_cancellation') }}:</p>
                                            <b class="col-md-8 col-sm-12">{{ withdraw.reason || '-' }}</b>
                                        </div>
                                        <div class="row mt-2">
                                            <p class="col-md-4 col-sm-12">{{$t('f_management.date_request') }}:</p>
                                            <b class="col-md-8 col-sm-12">{{ withdraw.created_at }}</b>
                                        </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="row">
                                                <p class="col-md-4 col-sm-12">{{ $t('f_management.transaction_number') }}:</p>
                                                <b class="col-md-8 col-sm-12">{{ withdraw.code || '-' }}</b>
                                            </div>
                                            <div class="row mt-2">
                                                <p class="col-md-4 col-sm-12">{{ $t('f_management.payment_method') }}:</p>
                                                <b class="col-md-8 col-sm-12 text-break">{{ paymentMethod }}</b>
                                            </div>
                                            <div class="row mt-2" v-if="!defaultCurrency.crypto">
                                                <p class="col-md-4 col-sm-12">{{ $t('finance.bank') }}:</p>
                                                <b class="col-md-8 col-sm-12">{{ provider.bank || '-' }}</b>
                                            </div>
                                            <div class="row mt-2">
                                                <p class="col-md-4 col-sm-12">{{ defaultCurrency.crypto ? $t('finance.wallet_address') : 'IBAN' }}:</p>
                                                <b class="col-md-8 col-sm-12">{{ wallet.address || '-' }}</b>
                                            </div>
                                            <template v-if="defaultCurrency.crypto">
                                                <div class="row mt-2">
                                                    <p class="col-md-4 col-sm-12">{{ $t('service.error') }}:</p>
                                                    <b class="col-md-8 col-sm-12">{{ withdraw.error || '-' }}</b>
                                                </div>
                                                <div class="row mt-2">
                                                    <p class="col-md-4 col-sm-12">{{ $t('merchant.txid') }}:</p>
                                                    <b class="col-md-8 col-sm-12">{{ withdraw.txhash || '-' }}</b>
                                                </div>
                                                <div class="row mt-2">
                                                    <p class="col-md-4 col-sm-12">Fireblocks Is:</p>
                                                    <b class="col-md-8 col-sm-12">{{ withdraw.fireblocks_id || '-' }}</b>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end: Invoice header-->
                    </div>
                </div>
                <!-- end::Card-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumb from '@/components/layout/Breadcrumb.vue'

export default {
  name: 'withdraw-detail',
  data() {
    return { }
  },
  components: {
    Breadcrumb
  },
  computed: {
    ...mapState({
      withdraw: (state) => state.withdrawTransaction.detailsModalData.withdraw,
      wallet: (state) => state.withdrawTransaction.detailsModalData.withdraw.wallet,
      provider: (state) => state.withdrawTransaction.detailsModalData.withdraw.provider,
      defaultCurrency: (state) => state.withdrawTransaction.detailsModalData.defaultCurrency,
    }),
    user() {
        return this.withdraw?.user || {};
    },
    userFullName() {
        const { name, surname } = this.user;
        if(name && surname) return `${name} ${surname}`
        return '-';
    },
    statusClass() {
        switch (this.withdraw?.status) {
            case 10:
                return 'badge-warning';
            case 20:
            case 70:
                return 'badge-light';
            case 30:
                return 'badge-success';
            case 40:
            case 50:
            case 60:
                return 'badge-danger';
            default:
                return '';
        }
    },
    statusLabel() {
        if(this.withdraw?.status) {
            return this.$t(`f_management.statuses.${this.withdraw.status}`)
        }
        return '-'
    },
    paymentMethod() {
        if(this.withdraw?.provider?.provider) {
            return this.$t(`f_management.payment_methods.${this.withdraw.provider.provider}`)
        }
        return '-';
    },
    breadcrumb_data() {
      return {
        title:  this.$t('f_management.title'),
        subtitle: [
          this.$t('f_management.withdraw_transactions')
        ]
      }
    }
  },
  created() {
      this.$store.dispatch('withdrawTransaction/GET_DETAILS_MODAL', this.$route.params.id)
  }
}
</script>